import { sortArrayBy } from '@campaignhub/javascript-utils'

import useSelector from '@hooks/useSelector'

import type { UserModel } from '@models/types'

export const generateUrls = (user?: Partial<UserModel>) => {
  const { id } = user || {}

  return {
    archivedUsersIndexUrl: '#/users?isArchived=true',
    editUserRelationsUrl: `#/users/${id}/edit/relations`,
    editUserUrl: `#/users/${id}/edit/`,
    usersIndexUrl: '#/users',
  }
}

// eslint-disable-next-line
export function useRelations(user: UserModel) {
  const { assetGroupId, userTypeId } = user || {}

  const entities = useSelector(reduxState => reduxState.entities)
  const { assets, userTypes } = entities

  const userImages = Object.values(assets).filter(asset => (
    asset.category === 'Image' && asset.assetGroupId === assetGroupId
  ))

  const sortedUserImages = sortArrayBy(userImages, 'asc', ({ sort }) => (sort || Infinity))

  const userType = userTypes[userTypeId] || {}

  return {
    coverImage: sortedUserImages[0] || {},
    images: sortedUserImages,
    userType,
  }
}
