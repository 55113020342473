import { useContext } from 'react'

import { useForm, useLatestEntity } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import { launchModal } from '@campaignhub/javascript-utils'

import useDispatch from '@hooks/useDispatch'
import useSelector from '@hooks/useSelector'

import * as clientUserActions from '@redux/modules/clientUser'

import defaultFormState, { requiredFields } from '@models/clientUser'

import PageContext from '@contexts/pageContext'

import type { AppDispatch } from '@redux/store'
import type { DeleteParams } from '@redux/modules/types'
import type { ClientUserModel, ClientUserRequestOptions } from '@models/types'

type BulkCreateClientUsersParams = {
  bulkCreateParams: { clientIds?: number[], userIds?: number[] },
  dispatch: AppDispatch,
  requestOptions?: ClientUserRequestOptions,
}

const bulkCreateClientUsers = (params: BulkCreateClientUsersParams) => {
  const {
    bulkCreateParams,
    dispatch,
    requestOptions,
  } = params
  const { bulkCreateClientUsers: bulkCreateFn } = clientUserActions

  return dispatch(bulkCreateFn(bulkCreateParams, requestOptions))
}

type DeleteClientUserParams = {
  clientUser: DeleteParams<ClientUserModel>,
  dispatch: AppDispatch,
}

const deleteClientUser = (params: DeleteClientUserParams) => {
  const { dispatch, clientUser } = params
  const { deleteClientUser: deleteFn } = clientUserActions

  return dispatch(deleteFn(clientUser))
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useClientUserForm(
  clientUser: Partial<ClientUserModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const clientUserForm = useForm(
    defaultFormState,
    { entity: clientUser, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [clientUser.id, clientUser.cacheKey],
  )

  return {
    ...clientUserForm,
  }
}

export const useRelations = (clientUser: Partial<ClientUserModel> = {}) => {
  const { clientId, userId } = clientUser

  const { clients, users } = useSelector(reduxState => reduxState.entities)

  const client = clientId && clients[clientId] ? clients[clientId] : {}
  const user = userId && users[userId] ? users[userId] : {}

  return {
    client,
    user,
  }
}

type Options = {
  performHttpRequests?: boolean,
  requestOptions?: ClientUserRequestOptions,
}

function useClientUser(initEntity: Partial<ClientUserModel> = {}, options: Options = {}) {
  const { entity: clientUser }: { entity: ClientUserModel } = useLatestEntity(initEntity, 'clientUsers')

  const dispatch = useDispatch()

  const { callbacks } = useContext(PageContext)

  const { client, user } = useRelations(clientUser)

  const {
    creating, deleting,
  } = useSelector(reduxState => reduxState.clientUsers)

  return {
    callbacks: {
      bulkCreateClientUsers: (
        bulkCreateParams: BulkCreateClientUsersParams['bulkCreateParams'],
        requestOptions?: ClientUserRequestOptions,
      ) => (
        bulkCreateClientUsers({
          bulkCreateParams,
          dispatch,
          requestOptions,
        })
      ),
      createOrEditClientUser: () => launchModal({
        callbacks,
        modalKey: 'CreateOrEditClientUserModal',
        payload: { clientUser },
      }),
      deleteClientUser: () => deleteClientUser({ clientUser, dispatch }),
    },
    client,
    clientUser,
    creating,
    deleting,
    user,
  }
}

export default useClientUser
