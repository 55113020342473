import { useMemo } from 'react'

import { matchFilterNumber, matchFilterString, sortArrayBy } from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useClientUsers from '@hooks/useClientUsers'
import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { UserModel, UserRequestOptions } from '@models/types'

const watchEntityKeys = ['users']

type UseUsersOptions = {
  filters?: {
    clientId?: number,
    isArchived?: boolean,
    string?: string,
    userTypeId?: number,
  },
  performHttpRequests?: boolean,
  requestOptions?: UserRequestOptions,
}

function useUsers(options: UseUsersOptions) {
  const { filters = {}, requestOptions } = options

  const {
    clientId: filterClientId,
    isArchived: filterIsArchived,
    string: filterString,
    userTypeId: filterUserTypeId,
  } = filters

  const {
    updatedEntities: { users: usersUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const clientUsersPayload = useClientUsers({
    filters: {
      clientId: filterClientId,
    },
  })

  const { filteredClientUsers } = clientUsersPayload

  const userIdsArray = filteredClientUsers.map(clientUser => clientUser.userId)

  const { users } = useSelector(reduxState => reduxState.entities)

  const filteredUsers = useMemo(() => {
    const filtered = Object.values(users).filter((user: UserModel) => {
      const { id, isArchived, name, userTypeId } = user

      const matchClientId = filterClientId ? userIdsArray.includes(id) : true

      const matchIsArchived = filterIsArchived !== undefined ? isArchived === filterIsArchived : true

      const matchId = id.toString() === filterString
      const matchTitle = matchFilterString(name, filterString)
      const matchString = matchId || matchTitle

      const matchUserTypeId = userTypeId != null ? matchFilterNumber(userTypeId, filterUserTypeId) : filterUserTypeId == null

      return matchClientId && matchIsArchived && matchString && matchUserTypeId
    })

    return sortArrayBy(filtered, 'asc', 'name')
  }, [usersUpdatedAt, JSON.stringify(filters)])

  const filteredUsersCount = filteredUsers.length
  const hasFilteredUsers = !!filteredUsersCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredUsersCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingUsers } = useReduxAction(
    'users',
    'loadUsers',
    {
      ...requestOptions,
      ...filtersWithOffset,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredUsers,
    filteredUsersCount,
    hasFilteredUsers,
    loading: loadingUsers,
  }
}

export default useUsers
